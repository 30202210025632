<template>
  <div>
    <p class="mb-0">
      Which one of the following statements about catalysts is
      <span class="text-bold text-danger">false</span>?
    </p>

    <v-form @submit.prevent="submitResponse">
      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mt-1 pl-6 mb-n6">
        <div v-for="option in options1" :key="option.value">
          <v-radio class="d-inline-block" :value="option.value" />
          <stemble-latex :content="option.expression" />
        </div>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question170b',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
      options1: [
        {
          expression: 'A catalyst is not typically used up in the reaction',
          value: '1',
        },
        {
          expression: 'A catalyst does not affect the products obtained from a reaction',
          value: '2',
        },
        {
          expression: 'Using a catalyst changes the way the reactants get to the product state',
          value: '3',
        },
        {
          expression: 'A catalyst is an intermediate in a reaction',
          value: '4',
        },
        {
          expression: 'A catalyst speeds up a reaction by lowering the activation energy',
          value: '5',
        },
      ],
    };
  },
};
</script>
